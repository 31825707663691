import { z } from 'zod';

export const fileField = z.object({
  uid: z.string(),
  created_by: z.string(),
  updated_by: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  content_type: z.string(),
  file_size: z.string(),
  filename: z.string(),
  title: z.string(),
  utl: z.string(),
});

export type FileSchema = z.infer<typeof fileField>;
