import { z } from 'zod';

export const cloudinaryField = z
  .union([
    z.array(
      z.object({
        public_id: z.string(),
        resource_type: z.string(),
        type: z.string(),
        format: z.string(),
        version: z.number(),
        url: z.string(),
        secure_url: z.string(),
        width: z.number().nullable(),
        height: z.number().nullable(),
        bytes: z.number().nullable(),
        duration: z.number().nullable(),
      }),
    ),
    z.object({}),
  ])
  .default({});

export type CloudinaryField = z.infer<typeof cloudinaryField>;
