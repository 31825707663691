import { G } from '@mobily/ts-belt';
import z from 'zod';
import { type CloudinaryImageFields } from '../../components/CloudinaryImageWrapper';
import { cloudinaryField as cloudinaryFieldSchema } from '../../../scripts/schemaGenerator/fields/cloudinaryField';

type CloudinaryField = z.infer<typeof cloudinaryFieldSchema>;
type KeepArray<T> = T extends unknown[] ? T : never;
type Cloudinary = KeepArray<CloudinaryField>;
export type CloudinarySchema = Cloudinary[number];

export type BasicCloudinaryAsset = {
  /**
   * The Cloudinary image's path consists of the image version, name, and format.
   * Example: 'v9876543210/landing-pages/hero.jpg'
   */
  path: string;
  width: number;
  height: number;
};

export const isCloudinary = (obj: unknown): obj is Cloudinary => {
  if (Array.isArray(obj)) {
    if (typeof obj[0] !== 'undefined' && 'secure_url' in obj[0]) {
      return true;
    }
  }

  return false;
};

export const getCloudinarySourceUrl = (cloudinaryField: CloudinaryField) => {
  if (isCloudinary(cloudinaryField)) {
    return cloudinaryField[0].secure_url;
  }

  return '';
};

/**
 * specifying fallback here is more about satisfying the types.
 * there's no reason to think cloudinary won't give us the correct values
 * here, but these are the types they specify.
 *
 * we include the top of the default responsive size range, used in CloudinaryImageV2,
 * so that all sizes are still generated.
 */
const defaultMaxImageSize = 2000;

export const getCloudinaryAsset = (
  cloudinaryField: CloudinaryField,
): BasicCloudinaryAsset | undefined => {
  if (isCloudinary(cloudinaryField)) {
    const { height, version, public_id, format, width } = cloudinaryField[0];
    // public_id may contain spaces, and other special characters, so we need to encode it
    const path = encodeURI(`v${version}/${public_id}.${format}`);

    return {
      path,
      height: height || defaultMaxImageSize,
      width: width || defaultMaxImageSize,
    };
  }

  return undefined;
};

export const getCloudinaryAspectRatio = (cloudinary: Cloudinary) => {
  const { width, height } = cloudinary[0];

  if (G.isNumber(width) && G.isNumber(height)) {
    return {
      width,
      height,
    };
  }

  return undefined;
};

export const getCloudinaryImageFields = ({
  alt,
  cloudinaryField,
  shouldUseV2 = false,
}: {
  alt: string;
  cloudinaryField: CloudinaryField;
  shouldUseV2?: boolean;
}): CloudinaryImageFields => {
  if (isCloudinary(cloudinaryField)) {
    return {
      alt,
      src: getCloudinarySourceUrl(cloudinaryField),
      aspectRatio: getCloudinaryAspectRatio(cloudinaryField),
      ...(shouldUseV2 && {
        cloudinaryAsset: getCloudinaryAsset(cloudinaryField),
      }),
    };
  }

  return {
    src: '',
    alt,
  };
};
