import { z } from 'zod';

const blockNode = z.enum([
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'blockquote',
  'p',
]);
const linkNode = z.literal('a');
const blockCode = z.literal('code');
const spanNode = z.literal('span');
const node = z.union([blockNode, linkNode, blockCode, spanNode]);

export type BlockNodeType = z.infer<typeof blockNode>;
export type LinkNodeType = z.infer<typeof linkNode>;
export type NodeType = z.infer<typeof node>;

const childText = z.object({
  text: z.string(),
  break: z.boolean().optional(),
  bold: z.boolean().optional(),
  inlineCode: z.boolean().optional(),
  italic: z.boolean().optional(),
  underline: z.boolean().optional(),
  strikethrough: z.boolean().optional(),
  classname: z.string().optional(),
});

export type ChildText = z.infer<typeof childText>;

const linkAttrs = z.object({ url: z.string(), target: z.string() });

export type LinkAttrs = z.infer<typeof linkAttrs>;

const childLink = z.object({
  type: linkNode,
  attrs: linkAttrs,
  uid: z.string(),
  children: z.array(childText),
});

export type ChildLink = z.infer<typeof childLink>;

const childSpan = z.object({
  type: spanNode,
  attrs: z.object({}),
  uid: z.string(),
  children: z.array(childText),
});

export type ChildSpan = z.infer<typeof childSpan>;

const childBlock = z.object({
  type: blockNode,
  attrs: z.object({
    style: z
      .object({
        'text-align': z
          .enum(['left', 'center', 'right', 'justify'])
          .default('left'),
      })
      .optional(),
    'class-name': z.string().optional(),
  }),
  uid: z.string(),
  children: z.array(z.union([childText, childLink, childSpan])),
});

export type ChildBlock = z.infer<typeof childBlock>;

export const contentStackJSONRichText = z
  .object({
    uid: z.string(),
    _version: z.number().optional(),
    attrs: z.object({}),
    children: z.array(childBlock),
    type: z.literal('doc'),
  })
  .nullish();

export type ContentStackJSONRichText = z.infer<typeof contentStackJSONRichText>;
